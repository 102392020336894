import type { FC } from 'react';
import React, { useEffect, useState } from 'react';

import type { TriggerProps } from '@atlaskit/popup';
import { Popup } from '@atlaskit/popup';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { useSessionData } from '@confluence/session-data';
import { Attribution, ErrorBoundary } from '@confluence/error-boundary';

import { QuickSummaryTrigger } from './QuickSummaryTrigger';
import { CustomPopupContainer } from './CustomPopupContainer';
import type { QuickSummaryButtonStyles } from './QuickSummaryButton';
import { QuickSummaryPopupContent } from './QuickSummaryPopupContent';
import { useQuickSummaryQueryParamOpen } from './hooks/useQuickSummaryQueryParamOpen';

export type QuickSummaryPopupProps = {
	contentId: string;
	contentType: string;
	lastModifiedDate?: string;
	source: string;
	contentPath: string;
	setIsFeedMenuOpen?: (open: boolean) => void;
	buttonStyles?: QuickSummaryButtonStyles;
	onOpen?: () => void; // custom onOpen callback for Advanced Search
	onClose?: () => void; // custom onClose callback for Advanced Search
};

export const QuickSummaryPopup: FC<QuickSummaryPopupProps> = ({
	contentId,
	contentType,
	lastModifiedDate = '',
	source,
	contentPath,
	setIsFeedMenuOpen,
	buttonStyles,
	onOpen,
	onClose,
}) => {
	const [isOpen, setIsOpen] = useState(false);
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { isAdminHubAIEnabled } = useSessionData();

	useQuickSummaryQueryParamOpen(isOpen, source, setIsOpen, contentId);

	const popupOffset: [number, number] = !isAdminHubAIEnabled ? [-10, 8] : [-34, 8];

	useEffect(() => {
		if (setIsFeedMenuOpen) {
			setIsFeedMenuOpen(!!isOpen);
		}
	}, [setIsFeedMenuOpen, isOpen]);

	const onPopupClose = () => {
		setIsOpen(false);
		onClose?.();
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				source: 'quickSummary',
				action: 'clicked',
				actionSubject: 'button',
				actionSubjectId: 'quickSummaryCloseOut',
				attributes: {
					contentId,
					contentType,
					source,
				},
			},
		}).fire();
	};

	if (contentType === 'page' || contentType === 'blogpost' || contentType === 'whiteboard') {
		return (
			<ErrorBoundary attribution={Attribution.SMARTS}>
				<Popup
					isOpen={isOpen}
					onClose={onPopupClose}
					placement="bottom"
					offset={popupOffset}
					content={() => (
						<QuickSummaryPopupContent
							contentId={contentId}
							contentType={contentType}
							lastModifiedDate={lastModifiedDate}
							source={source}
							contentPath={contentPath}
							setIsOpen={setIsOpen}
						/>
					)}
					popupComponent={CustomPopupContainer}
					shouldFlip={false}
					shouldRenderToParent
					trigger={(triggerProps: TriggerProps) => (
						<QuickSummaryTrigger
							contentId={contentId}
							triggerProps={triggerProps}
							isOpen={isOpen}
							setIsOpen={setIsOpen}
							source={source}
							buttonStyles={buttonStyles}
							onOpen={onOpen}
							onClose={onClose}
						/>
					)}
				/>
			</ErrorBoundary>
		);
	}

	return null;
};
